import React from "react"
import Seo from "../../components/seo"
import Layout from "../../Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import { Link } from "gatsby"

const RefundPolicyPage = () => {
  return (
    <Layout>
      <Seo title="Refund Policy" />
      <Breadcrumbs title={"Refund Policy"} />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2 className="h4">Placing Orders</h2>
            <p>
              All the delivery requests made though the website are final, and
              cannot be cancelled. As a customer, you are responsible for making
              sure that the requests you make are correct.
            </p>

            <h2 className="h4">Refunding</h2>
            <p>
              However, we realize that exceptional circumstances can take place
              with regard to the character of the products we supply -
              perishable items. Therefore, unless we haven’t come to an
              understanding with the customer, the orders that cannot be
              delivered under the requested timeline will be fully refunded.
            </p>
            <p>
              The quality of the delivered items have to be checked at the same
              time you receive the goods. Any complaints have to be raised after
              inspection on delivery. No complaints made after the delivery will
              not be taken to consideration and are considered as not in our
              responsibility.
            </p>
            <hr className="uk-margin-top" />
            <p>
              If you have any further queries to be clarified, please do not
              hesitate to contact us via our FB messenger in the website or
              sending us en <Link to="/contact-us">email</Link>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RefundPolicyPage
